import { Routes, Route } from "react-router-dom";
import Route404 from "../pages/404";
import WelcomePage from "../pages/welcome";
const AppRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<WelcomePage />} />
      <Route path="*" element={<Route404 />} />
    </Routes>
  );
};

export default AppRoutes;
