import { getLogoDataUrl } from "@orangep/util";
import "./welcome.css";

const WelcomePage = () => {
  return (
    <>
      <div className="container">
        <div className="left-section">
          <div className="inner-content">
            <img
              src={getLogoDataUrl()}
              alt="Orange Production Limited"
              width="100%"
              style={{ display: "block", margin: "auto" }}
            />
            <div className="company-name">
              ตำบล กระทุ่มล้ม อำเภอสามพราน นครปฐม 73220
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WelcomePage;
