import { useEffect, useState } from "react";
// import { Observable, Subscription } from "rxjs";
import { Helmet } from "react-helmet";
import { BrowserRouter as Router } from "react-router-dom";
// import liff from "@line/liff";

// import { initUtil, subscribeNumberSubject, getEnvVar } from "@orangep/util";
// import { getBehaviorObservable } from "@orangep/util";
import AppRoutes from "./components/app-routes";

export default function Root(props) {
  // const [token, setToken] = useState<string>("");
  // const [intervalValue, setIntervalValue] = useState<number>(0);
  // let token$: any;
  // let interval2$: Subscription = null;

  return (
    <section style={{ fontFamily: "Kanit" }}>
      <Helmet>
        <title>Orange Production Application</title>
      </Helmet>
      <Router>
        <AppRoutes />
      </Router>
    </section>
  );
}
